import React from 'react';
import {
	NAV_STYLE_DRAWER,
	NAV_STYLE_FIXED,
	NAV_STYLE_MINI_SIDEBAR,
	NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
	NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
	TAB_SIZE
} from '../../constants/ThemeSetting';
import { useSelector } from 'react-redux';
import Sidebar from '../Sidebar';

// Define an array of navStyles where the sidebar should be visible
const SIDEBAR_VISIBLE_ON = [
	NAV_STYLE_FIXED,
	NAV_STYLE_DRAWER,
	NAV_STYLE_MINI_SIDEBAR,
	NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
	NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
];

const AppSidebar = ({ navStyle }) => {
	// Get the screen width from the Redux state
	const width = useSelector(({ common }) => common.width);

	// Conditionally render the Sidebar component based on screen width and navStyle
	if (width < TAB_SIZE || SIDEBAR_VISIBLE_ON.includes(navStyle)) {
		return <Sidebar />;
	}

	// If the condition is not met, return null (no Sidebar is rendered)
	return null;
};

export default React.memo(AppSidebar);
