import React from 'react';
import { Button, Form, Input, Menu } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import dashboard from '../../assets/images/new-icons/dashboard.svg';
import addresses from '../../assets/images/new-icons/addresses.svg';
import api from '../../assets/images/new-icons/api.svg';
import privacyPolicy from '../../assets/images/new-icons/privacy_policy.svg';
import shipNow from '../../assets/images/new-icons/ship_now.svg';
import termsOfServices from '../../assets/images/new-icons/terms_of_services.svg';
import bannedItems from '../../assets/images/new-icons/banned_items.svg';
import bulkOrder from '../../assets/images/new-icons/bulk_order.svg';
import contactUs from '../../assets/images/new-icons/contact_us.svg';
import myOrders from '../../assets/images/new-icons/my_orders.svg';
import myWallet from '../../assets/images/new-icons/my_wallet.svg';
import packagingGuides from '../../assets/images/new-icons/packaging_guides.svg';
import CustomScrollbars from 'util/CustomScrollbars';
import SidebarLogo from './SidebarLogo';
import UserProfile from './UserProfile';
import {
	NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
	NAV_STYLE_NO_HEADER_MINI_SIDEBAR
} from '../../constants/ThemeSetting';
import IntlMessages from '../../util/IntlMessages';
import { useSelector } from 'react-redux';
const SubMenu = Menu.SubMenu;
const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
	const { navStyle } = useSelector(({ settings }) => settings);
	const history = useHistory();
	const pathname = useSelector(({ common }) => common.pathname);

	const getNoHeaderClass = (navStyle) => {
		if (
			navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
			navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
		) {
			return 'gx-no-header-notifications';
		}
		return '';
	};

	const selectedKeys = pathname.substr(1);
	const defaultOpenKeys = selectedKeys.split('/')[1];
	const onFinish = (values) => {
		history.push('/track-shipment', { state: values });
	};
	const onFinishFailed = (values) => {
		console.log(values);
	};
	return (
		<>
			{/* hamurger icon */}
			<SidebarLogo
				sidebarCollapsed={sidebarCollapsed}
				setSidebarCollapsed={setSidebarCollapsed}
			/>

			<div className="gx-sidebar-content">
				<div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
					<UserProfile />
					{/* for tracking the shipment*/}
					<Form
						initialValues={{ remember: true }}
						name="basic"
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						className="gx-signin-form flex items-center gx-form-row0"
					>
						<Button type="text" htmlType="submit" className="m-0 p-0">
							<ul className="gx-app-nav">
								<li>
									<i className="icon icon-search-new" />
								</li>
							</ul>
						</Button>

						<Form.Item
							rules={[{ required: true, message: 'Please input your AWB/Order Id!' }]}
							name="orderIdAwb"
						>
							<Input placeholder="AWB/OrderId" className="sidebar__trackshipment" />
						</Form.Item>
					</Form>
				</div>
				<CustomScrollbars className="gx-layout-sider-scrollbar">
					<Menu
						defaultOpenKeys={[defaultOpenKeys]}
						selectedKeys={[selectedKeys]}
						theme="lite"
						mode="inline"
					>
						<Menu.Item key="dashboard">
							<Link to="/dashboard">
								<i className="icon baseline-allign">
									<img src={dashboard} alt="dashboard " />
								</i>
								<span>
									<IntlMessages id="sidebar.dashboard" />
								</span>
							</Link>
						</Menu.Item>
						<Menu.Item key="book-orders">
							<Link to="/book-orders">
								<i className="icon baseline-allign">
									<img src={shipNow} alt="Book Orders " />
								</i>

								<span>
									<IntlMessages id="sidebar.book-orders" />
								</span>
							</Link>
						</Menu.Item>

						<Menu.Item key="my-orders">
							<Link to="/my-orders">
								<i className="icon baseline-allign">
									<img src={myOrders} alt="my orders " />
								</i>

								<span>
									<IntlMessages id="sidebar.my-orders" />
								</span>
							</Link>
						</Menu.Item>
						<Menu.Item key="my-addresses">
							<Link to="/my-address">
								<i className="icon baseline-allign">
									<img src={addresses} alt="my addresses " />
								</i>

								<span>
									<IntlMessages id="sidebar.my-addresses" />
								</span>
							</Link>
						</Menu.Item>
						<SubMenu
							key="bulkUpload"
							title={
								<>
									<i className="icon baseline-allign">
										<img src={bulkOrder} alt="packaging guides" />
									</i>
									<span>Bulk Upload</span>
								</>
							}
							className="custom-submenu"
						>
							<Menu.Item key="upload-sheet">
								<Link to="/upload-sheet">
									<i className="icon baseline-allign">
										<img src={bulkOrder} alt="bulk upload " />
									</i>

									<span>
										<IntlMessages id="sidebar.upload-sheet" />
									</span>
								</Link>
							</Menu.Item>
							<Menu.Item key="bulk-list">
								<Link to="/bulk-list">
									<i className="icon baseline-allign">
										<img src={bulkOrder} alt="bulk upload " />
									</i>

									<span>
										<IntlMessages id="sidebar.bulk-list" />
									</span>
								</Link>
							</Menu.Item>
							<Menu.Item key="order-tracking">
								<Link to="/order-tracking">
									<i className="icon baseline-allign">
										<img src={bulkOrder} alt="bulk upload " />
									</i>

									<span>
										<IntlMessages id="sidebar.order-tracking" />
									</span>
								</Link>
							</Menu.Item>
						</SubMenu>
						<Menu.Item key="coreyo-wallet">
							<Link to="/coreyo-wallet">
								<i className="icon baseline-allign">
									<img src={myWallet} alt="My wallet " />
								</i>

								<span>
									<IntlMessages id="sidebar.coreyo-wallet" />
								</span>
							</Link>
						</Menu.Item>

						<Menu.Item key="api-dashboard">
							<Link to="/api-dashboard">
								<i className="icon baseline-allign">
									<img src={api} alt="developer dasboard " />
								</i>

								<span>
									<IntlMessages id="sidebar.api-dashboard" />
								</span>
							</Link>
						</Menu.Item>
						<Menu.Item key="contact-us">
							<Link to="/contact-us">
								<i className="icon baseline-allign">
									<img src={contactUs} alt="Contact Us " />
								</i>

								<span>
									<IntlMessages id="sidebar.contact-us" />
								</span>
							</Link>
						</Menu.Item>

						<SubMenu
							key="policy"
							title={
								<>
									<i className="icon baseline-allign">
										<img src={termsOfServices} alt="Term of Services" />
									</i>
									<span>Terms</span>
								</>
							}
							className="custom-submenu"
						>
							<Menu.Item key="terms-of-services">
								<Link to="/terms-of-services">
									<i className="icon baseline-allign">
										<img src={termsOfServices} alt="Term of Services " />
									</i>

									<span>
										<IntlMessages id="sidebar.terms-of-services" />
									</span>
								</Link>
							</Menu.Item>
							<Menu.Item key="privacy-policy" className="gx-p-0 gx-m-0">
								<Link to="/privacy-policy">
									<i className="icon baseline-allign">
										<img src={privacyPolicy} alt="Privacy Policy " />
									</i>

									<span>
										<IntlMessages id="sidebar.privacy-policy" />
									</span>
								</Link>
							</Menu.Item>
						</SubMenu>
						<SubMenu
							key="guidelines"
							title={
								<>
									<i className="icon baseline-allign">
										<img src={packagingGuides} alt="packaging guides" />
									</i>
									<span>Guidelines</span>
								</>
							}
							className="custom-submenu"
						>
							<Menu.Item key="packaging-guides">
								<Link to="/packaging-guides">
									<i className="icon baseline-allign">
										<img src={packagingGuides} alt="Packaging Guide" />
									</i>

									<span>
										<IntlMessages id="sidebar.packaging-guides" />
									</span>
								</Link>
							</Menu.Item>
							<Menu.Item key="banned-items">
								<Link to="/banned-items">
									<i className="icon baseline-allign">
										<img src={bannedItems} alt="Banned items " />
									</i>

									<span>
										<IntlMessages id="sidebar.banned-items" />
									</span>
								</Link>
							</Menu.Item>
						</SubMenu>

						{/* </Panel>
						</Collapse> */}
					</Menu>
				</CustomScrollbars>
			</div>
		</>
	);
};

export default React.memo(SidebarContent);
