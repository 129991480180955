export const FOOTER_TEXT = process.env.REACT_APP_FOOTER_TEXT;
export const API_KEY = process.env.REACT_APP_API_KEY;
export const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;
export const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;
export const STORAGE_BUCKET = process.env.REACT_APP_STORAGE_BUCKET;
export const MESSAGING_SENDER_ID = process.env.REACT_APP_MESSAGING_SENDER_ID;
export const APP_ID = process.env.REACT_APP_APP_ID;
export const MEASUREMENT_ID = process.env.REACT_APP_MEASUREMENT_ID;
export const API_URL = process.env.REACT_APP_API_URL;
export const LOGIN_API_URL = process.env.REACT_APP_LOGIN_API_URL;
export const CLOUDINARY_URL = process.env.REACT_APP_CLOUDINARY_URL;
export const MULTIPACKAGE = process.env.REACT_APP_MULTIPACKAGE;
export const INTERNATIONAL_ACTIVE = process.env.REACT_APP_INTERNATIONAL_ACTIVE;
// cutoff
export const CUTTOFF_XPRESSBEES = process.env.REACT_APP_CUTTOFF_XPRESSBEES;
export const CUTTOFF_AMAZON = process.env.REACT_APP_CUTTOFF_AMAZON;
export const CUTTOFF_CRITICALOG = process.env.REACT_APP_CUTTOFF_CRITICALOG;
export const CUTTOFF_SHADOWFAX = process.env.REACT_APP_CUTTOFF_SHADOWFAX;
export const CUTTOFF_ECOM_EXPRESS = process.env.REACT_APP_CUTTOFF_ECOM_EXPRESS;
export const CUTTOFF_DELHIVERY = process.env.REACT_APP_CUTTOFF_DELHIVERY;
export const CUTTOFF_DTDC = process.env.REACT_APP_CUTTOFF_DTDC;
export const CUTTOFF_DEFAULT = process.env.REACT_APP_CUTTOFF_DEFAULT;
export const CUTTOFF_BLUEDART = process.env.REACT_APP_CUTTOFF_BLUEDART;
export const CUTTOFF_SMARTR = process.env.REACT_APP_CUTTOFF_SMARTR;
export const CUTTOFF_FEDEX = process.env.REACT_APP_CUTTOFF_FEDEX;
export const CUTTOFF_INDIA_POST = process.env.REACT_APP_CUTTOFF_INDIA_POST;
export const CUTTOFF_EKART = process.env.REACT_APP_CUTTOFF_EKART;
export const CUTTOFF_MOVIN = process.env.REACT_APP_CUTTOFF_MOVIN;

export const CUTOFF_INDIAPOST_QWQER = process.env.REACT_APP_CUTOFF_INDIAPOST_QWQER;
export const CUTOFF_INDIAPOST_DUNZO = process.env.REACT_APP_CUTOFF_INDIAPOST_DUNZO;
export const CUTOFF_INDIAPOST_SELF = process.env.REACT_APP_CUTOFF_INDIAPOST_SELF;
export const CUTTOFF_GATI = process.env.REACT_APP_CUTTOFF_GATI;
export const CUTTOFF_SHREEMARUTI = process.env.REACT_APP_CUTTOFF_SHREEMARUTI;
export const MULTIPACKAGE_XPRESSBEES_CARGO_MAX_LIMIT =
	process.env.REACT_APP_MULTIPACKAGE_XPRESSBEES_CARGO_MAX_LIMIT;
export const MULTIPACKAGE_XPRESSBEES_CARGO_MIN_LIMIT =
	process.env.REACT_APP_MULTIPACKAGE_XPRESSBEES_CARGO_MIN_LIMIT;
export const MULTIPACKAGE_GATI_MAX_LIMIT = process.env.REACT_APP_MULTIPACKAGE_GATI_MAX_LIMIT;
export const MULTIPACKAGE_GATI_MIN_LIMIT = process.env.REACT_APP_MULTIPACKAGE_GATI_MIN_LIMIT;
export const BULK_SHIPMENT_LABEL_LINK = process.env.REACT_APP_BULK_SHIPMENT_LABEL_LINK;
export const SPEEDPOST_ACTIVE = process.env.REACT_APP_SPEEDPOST_ACTIVE;
export const RAZORPAY_UPI_ENABLED = process.env.REACT_APP_RAZORPAY_UPI_ENABLED;
export const RAZORPAY_WALLET_ENABLED = process.env.REACT_APP_RAZORPAY_WALLET_ENABLED;
export const RAZORPAY_CARD_ENABLED = process.env.REACT_APP_RAZORPAY_CARD_ENABLED;
export const RAZORPAY_NETBANKING_ENABLED = process.env.REACT_APP_RAZORPAY_NETBANKING_ENABLED;
export const GEO_API_KEY = process.env.REACT_APP_GEO_API_KEY;
export const RAZORPAT_ENABLED = process.env.REACT_APP_RAZORPAY_ENABLED;
export const POSTPAID_USER = process.env.REACT_APP_POSTPAID_USER;
export const COD_ENABLED = process.env.REACT_APP_COD_ENABLED;
export const PROVIDER_PRIORITY = process.env.REACT_APP_PROVIDER_PRIORITY;
export const PROVIDER_PRIORITY_NAME = process.env.REACT_APP_PROVIDER_PRIORITY_NAME;
