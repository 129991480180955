import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';

import AppLocale from '../../lngProvider';
import MainApp from './MainApp';
import SignIn from '../SignIn';
import SignUp from '../SignUp';
import { setInitUrl } from '../../appRedux/actions';

import {
	LAYOUT_TYPE_BOXED,
	LAYOUT_TYPE_FRAMED,
	LAYOUT_TYPE_FULL,
	NAV_STYLE_ABOVE_HEADER,
	NAV_STYLE_BELOW_HEADER,
	NAV_STYLE_DARK_HORIZONTAL,
	NAV_STYLE_DEFAULT_HORIZONTAL,
	NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
	THEME_TYPE_DARK
} from '../../constants/ThemeSetting';
import CircularProgress from '../../components/CircularProgress';
import { useAuth } from '../../authentication';

const RestrictedRoute = ({ component: Component, location, authUser, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			authUser ? (
				<Component {...props} />
			) : (
				<Redirect
					to={{
						pathname: '/signin',
						state: { from: location }
					}}
				/>
			)
		}
	/>
);

const setLayoutType = (layoutType) => {
	// Function to set the layout type based on the provided value
	if (layoutType === LAYOUT_TYPE_FULL) {
		document.body.classList.remove('boxed-layout');
		document.body.classList.remove('framed-layout');
		document.body.classList.add('full-layout');
	} else if (layoutType === LAYOUT_TYPE_BOXED) {
		document.body.classList.remove('full-layout');
		document.body.classList.remove('framed-layout');
		document.body.classList.add('boxed-layout');
	} else if (layoutType === LAYOUT_TYPE_FRAMED) {
		document.body.classList.remove('boxed-layout');
		document.body.classList.remove('full-layout');
		document.body.classList.add('framed-layout');
	}
};

const setNavStyle = (navStyle) => {
	// Function to set the navigation style based on the provided value
	if (
		navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
		navStyle === NAV_STYLE_DARK_HORIZONTAL ||
		navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
		navStyle === NAV_STYLE_ABOVE_HEADER ||
		navStyle === NAV_STYLE_BELOW_HEADER
	) {
		document.body.classList.add('full-scroll');
		document.body.classList.add('horizontal-layout');
	} else {
		document.body.classList.remove('full-scroll');
		document.body.classList.remove('horizontal-layout');
	}
};

const App = () => {
	// useSelector -> The selector function receives the complete Redux state as an argument.
	const locale = useSelector(({ settings }) => settings.locale);

	const navStyle = useSelector(({ settings }) => settings.navStyle);
	const layoutType = useSelector(({ settings }) => settings.layoutType);
	const themeType = useSelector(({ settings }) => settings.themeType);
	const isDirectionRTL = useSelector(({ settings }) => settings.isDirectionRTL);
	const initURL = useSelector(({ settings }) => settings.initURL);

	const { authUser, isLoadingUser } = useAuth(); //states of authentication
	const dispatch = useDispatch();

	const location = useLocation();
	const history = useHistory();
	const match = useRouteMatch();

	useEffect(() => {
		// Update the HTML class and attribute for RTL layout
		if (isDirectionRTL) {
			document.documentElement.classList.add('rtl');
			document.documentElement.setAttribute('data-direction', 'rtl');
		} else {
			document.documentElement.classList.remove('rtl');
			document.documentElement.setAttribute('data-direction', 'ltr');
		}
	}, [isDirectionRTL]);

	useEffect(() => {
		// Set the HTML lang attribute based on the current locale
		if (locale) document.documentElement.lang = locale.locale;
	}, [locale]);

	useEffect(() => {
		// Redirect to the dashboard if the user is authenticated and on the root path
		if (authUser && location.pathname === '/') {
			history.push('/dashboard');
		}
	}, [location, authUser]);

	useEffect(() => {
		// Add or remove the dark-theme class based on the themeType
		if (themeType === THEME_TYPE_DARK) {
			document.body.classList.add('dark-theme');
		} else if (document.body.classList.contains('dark-theme')) {
			document.body.classList.remove('dark-theme');
		}
	}, [themeType]);

	useEffect(() => {
		// Set the initial URL in the redux store if not already set
		if (initURL === '') {
			dispatch(setInitUrl(location.pathname)); //storing the pathname into initUrl state
		}
	}, []);

	useEffect(() => {
		// Redirect the user to the appropriate URL after authentication
		if (!isLoadingUser) {
			if (!authUser) {
				history.push('/signin');
				console.log(initURL, 'initurl');
			} else if (
				initURL === '' ||
				initURL === '/' ||
				initURL === '/signin' ||
				initURL === '/signup'
			) {
				console.log(initURL, 'initurl');
				history.push('/dashboard');
			} else {
				history.push(initURL);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authUser]);

	useEffect(() => {
		// Set the layout type and navigation style on component mount and when the values change
		setLayoutType(layoutType);
		setNavStyle(navStyle);
	}, [layoutType, navStyle]);

	const currentAppLocale = AppLocale[locale.locale];

	return isLoadingUser ? (
		<CircularProgress /> //loader
	) : (
		// The parent component is App.js, where we have defined both public and restricted routes.
		// provides a configuration to all React components underneath itself via the context API. In the render tree all components will have access to the provided config.
		<ConfigProvider locale={currentAppLocale.antd} direction={isDirectionRTL ? 'rtl' : 'ltr'}>
			{/* //t provides components and APIs that help developers create multilingual applications, allowing them to support multiple languages and adapt content based on the user's locale. */}
			<IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
				{/* IntlProvider provides localization support for React components */}
				<Switch>
					{/* Define routes for different pages */}
					<Route exact path="/signin" component={SignIn} />
					<Route exact path="/signup" component={SignUp} />
					<RestrictedRoute
						path={`${match.url}`}
						authUser={authUser}
						location={location}
						component={MainApp}
					/>
				</Switch>
			</IntlProvider>
		</ConfigProvider>
	);
};

export default App;
