import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const antIcon = (
	<LoadingOutlined
		style={{
			fontSize: 70
		}}
		spin
	/>
);
const CircularProgress = ({ className }) => (
	<div className={`loader ${className}`}>
		<Spin indicator={antIcon} />
	</div>
);
export default CircularProgress;
