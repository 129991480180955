import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { httpClient } from '../../../util/Api';
import { LOGIN_API_URL } from '../../../util/config';

export const useProvideAuth = () => {
	const [authUser, setAuthUser] = useState(null);
	const [error, setError] = useState('');
	const [isLoadingUser, setLoadingUser] = useState(true);
	const history = useHistory();
	const [successMessage, setSuccessMessage] = useState('');

	const [isLoading, setLoading] = useState(false);

	const fetchStart = () => {
		setLoading(true);
		setError('');
	};

	const fetchSuccess = () => {
		setLoading(false);
		setError('');
	};

	const fetchError = (error) => {
		setLoading(false);
		setError(error);
	};

	const userLogin = (user, callbackFun) => {
		fetchError('');
		fetchStart();
		httpClient
			.post(`${LOGIN_API_URL}`, user)
			.then(({ data }) => {
				if (data.data) {
					fetchSuccess();
					httpClient.defaults.headers.common['authorization'] = data.data.user.token;
					localStorage.setItem(
						'token',
						data.data.user.token,
						httpClient.defaults.headers.common['authorization']
					);
					history.push('/dashboard');
					getAuthUser();
					if (callbackFun) callbackFun();
				} else {
					fetchError(error?.response?.data?.message);
				}
			})
			.catch(function (error) {
				fetchError(error?.response?.data?.message);
				httpClient.defaults.headers.common['authorization'] = '';
			});
	};
	const forgotPassword = (payload, callbackFun) => {
		setSuccessMessage('');
		// return httpClient.post(`user/forgot-password`, payload);
		fetchStart();
		httpClient
			.post('user/forgot-password', payload)
			.then(({ data }) => {
				if (data.data) {
					fetchSuccess();
					if (data?.data === 'success') {
						setSuccessMessage('We have sent reset password link to your email.');
					}

					if (callbackFun) callbackFun();
				} else {
					fetchError(data.error);
				}
			})
			.catch(function (error) {
				fetchError(error?.response?.data?.message);
			});
	};
	const resetPassword = (payload, messageApi, handleOkForReset) => {
		setSuccessMessage('');
		fetchError('');
		// return httpClient.post(`user/forgot-password`, payload);
		fetchStart();
		httpClient
			.patch('user/reset-password', payload)
			.then(({ data }) => {
				if (data.httpCode === 200) {
					fetchSuccess();
					messageApi.open({
						type: 'success',
						content: data?.message
					});
					handleOkForReset();
				} else {
					fetchError(data.error);
				}
			})
			.catch(function (error) {
				fetchError(error?.response?.data?.message);
			});
	};

	const userSignup = (user, callbackFun) => {
		fetchStart();
		fetchError(' ');
		httpClient
			.post('business/register', user)
			.then(({ data }) => {
				if (data.data) {
					fetchSuccess();
					history.push('/signin');
					if (callbackFun) callbackFun();
				} else {
					fetchError(data.message);
				}
			})
			.catch(function (error) {
				console.log(error, 'error');
				fetchError(error?.response?.data?.message);
			});
	};

	const sendPasswordResetEmail = (email, callbackFun) => {
		fetchStart();

		setTimeout(() => {
			fetchSuccess();
			if (callbackFun) callbackFun();
		}, 300);
	};

	const userSignOut = (callbackFun) => {
		fetchStart();
		fetchError('');
		httpClient
			.get('user/logout')
			.then(({ data }) => {
				if (data.data) {
					fetchSuccess();
					httpClient.defaults.headers.common['authorization'] = '';
					localStorage.removeItem('token');
					setAuthUser(false);
					if (callbackFun) callbackFun();
				} else {
					fetchError(data.error);
				}
			})
			.catch(function (error) {
				fetchError(error?.response?.data?.message);
			});
	};

	const getAuthUser = () => {
		fetchStart();
		fetchError('');
		const token = localStorage.getItem('token');
		console.log(token, 'token');
		if (token) {
			httpClient.defaults.headers.common['authorization'] = token;
		}

		httpClient
			.get('user/auth/me')
			.then(({ data }) => {
				console.log(data, 'data');
				if (data.data.data) {
					setAuthUser(data.data.data);
				}
				setLoadingUser(false);
			})
			.catch(function (error) {
				localStorage.removeItem('token');
				httpClient.defaults.headers.common['authorization'] = '';
				setLoadingUser(false);
				fetchError(error?.response?.data?.message);
			});
	};

	// Subscribe to user on mount
	// Because this sets state in the callback it will cause any ...
	// ... component that utilizes this hook to re-render with the ...
	// ... latest auth object.

	useEffect(() => {
		const token = localStorage.getItem('token');
		if (token) {
			httpClient.defaults.headers.common['authorization'] = token;
			httpClient
				.get('user/auth/me')
				.then(({ data }) => {
					if (data.data.data) {
						setAuthUser(data.data.data);
					}
					setLoadingUser(false);
				})
				.catch(function () {
					localStorage.removeItem('token');
					httpClient.defaults.headers.common['authorization'] = '';
					setLoadingUser(false);
				});
		} else {
			httpClient.defaults.headers.common['authorization'] = '';
			setLoadingUser(false);

			// history.push('/signin');
		}
	}, []);

	// Return the user object and auth methods
	return {
		isLoadingUser,
		isLoading,
		authUser,
		error,
		setError,
		setAuthUser,
		getAuthUser,
		userLogin,
		userSignup,

		userSignOut,
		sendPasswordResetEmail,
		successMessage,
		setSuccessMessage,
		resetPassword,
		forgotPassword
	};
};
