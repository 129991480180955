import React from 'react';
import { Button, Form, Input } from 'antd';
import { Link } from 'react-router-dom';

import IntlMessages from 'util/IntlMessages';
import { useAuth } from '../authentication';
import AppNotificationContainer from '../components/AppNotificationContainer';

const FormItem = Form.Item;

const SignUp = () => {
	const { isLoading, error, userSignup } = useAuth(); //getting authentication props from useAuth()

	const onFinishFailed = (errorInfo) => {};

	const onFinish = (values) => {
		userSignup({ ...values, country_code: '+91' }); //country code is hardcoded as "+91"
	};

	return (
		<div style={{ height: '100vh' }}>
			<div
				className="gx-app-login-wrap signup"
				style={{ paddingTop: 150, paddingBottom: 20 }}
			>
				<div className="gx-app-logo">
					<img alt="example" src="/assets/images/logo.png" />
				</div>
				<br />
				<div className="gx-app-login-container">
					<div className="gx-app-login-main-content">
						<div className="gx-app-logo-content">
							<div className="gx-app-logo-content-bg">
								{/* <img src='https://via.placeholder.com/272x395' alt='Neature'/> */}
							</div>
							<div className="gx-app-logo-wid">
								<h1>
									<IntlMessages id="app.userAuth.signUp" />
								</h1>
								<p>
									<IntlMessages id="app.userAuth.bySigning" />
								</p>
								<p>
									<IntlMessages id="app.userAuth.getAccount" />
								</p>
							</div>
						</div>

						<div className="gx-app-login-content">
							<Form
								initialValues={{ remember: true }}
								name="basic"
								onFinish={onFinish}
								onFinishFailed={onFinishFailed}
								className="gx-signin-form gx-form-row0"
							>
								<div className="flex gap-10">
									<FormItem
										rules={[
											{
												required: true,
												message: 'Please input your first name!'
											},
											{
												pattern: /^[a-zA-Z0-9\d\-_@/.,\s]+$/,
												message: 'Only , . / - _ special characters allowed'
											}
										]}
										name="first_name"
									>
										<Input placeholder="First Name" />
									</FormItem>

									<FormItem
										rules={[
											{
												required: true,
												message: 'Please input your last name!'
											}
										]}
										name="last_name"
									>
										<Input placeholder="Last Name" />
									</FormItem>
								</div>

								<FormItem
									name="email"
									rules={[
										{
											required: true,
											type: 'email',
											message: 'The input is not valid E-mail!'
										}
									]}
								>
									<Input placeholder="Email" />
								</FormItem>
								<FormItem
									rules={[
										{
											required: true,
											message: 'Please input your mobile number!'
										},
										{
											pattern: /^[0-9]{10}$/,
											message: 'Please enter a valid mobile number'
										}
									]}
									name="mobile_no"
								>
									<Input type="number" placeholder="Mobile Number" />
								</FormItem>
								<FormItem
									rules={[
										{
											required: true,
											message: 'Please input your address line 1!'
										},
										{
											pattern: /^[a-zA-Z0-9\d\-_@/.,\s]+$/,
											message: 'Only , . / - _ special characters allowed'
										}
									]}
									name="address1"
								>
									<Input placeholder="Address Line 1" />
								</FormItem>
								<FormItem
									rules={[
										{
											required: true,
											message: 'Please input your address line 2!'
										},
										{
											pattern: /^[a-zA-Z0-9\d\-_@/.,\s]+$/,
											message: 'Only , . / - _ special characters allowed'
										}
									]}
									name="address2"
								>
									<Input placeholder="Address Line 2" />
								</FormItem>
								<div className="flex gap-10">
									<FormItem
										rules={[
											{
												required: true,
												message: 'Please input your postal code!'
											},
											{
												pattern: /^[0-9]{6}$/,
												message: 'Please enter a valid postal code'
											}
										]}
										name="postal_code"
									>
										<Input placeholder="Postal Code" type="number" />
									</FormItem>

									<FormItem
										rules={[
											{ required: true, message: 'Please input your city!' }
										]}
										name="city"
									>
										<Input placeholder="City" />
									</FormItem>
								</div>
								<div className="flex gap-10">
									<FormItem
										rules={[
											{ required: true, message: 'Please input your state!' }
										]}
										name="state"
									>
										<Input placeholder="State" />
									</FormItem>
									<FormItem
										rules={[
											{
												required: true,
												message: 'Please input your country!'
											}
										]}
										name="country"
									>
										<Input placeholder="Country" />
									</FormItem>
								</div>

								<FormItem
									rules={[
										{
											required: true,
											message: 'Please input your company name!'
										}
									]}
									name="company_name"
								>
									<Input placeholder="Company name" />
								</FormItem>

								<div className="flex gap-10">
									<FormItem
										rules={[
											{
												required: true,
												message: 'Please input your sales id!'
											}
										]}
										name="sales_id"
									>
										<Input placeholder="Sales Id" type="text" />
									</FormItem>
									<FormItem
										rules={[
											{
												required: true,
												message: 'Please input your gst number!'
											}
										]}
										name="gst_number"
									>
										<Input placeholder="gst number" />
									</FormItem>
								</div>

								<FormItem>
									<Button type="primary" className="gx-mb-0" htmlType="submit">
										<IntlMessages id="app.userAuth.signUp" />
									</Button>
									<span>
										<IntlMessages id="app.userAuth.or" />
									</span>{' '}
									<Link to="/signin">
										<IntlMessages id="app.userAuth.signIn" />
									</Link>
								</FormItem>
							</Form>
						</div>
						<AppNotificationContainer loading={isLoading} error={error} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default SignUp;
