import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import HorizontalDefault from '../Topbar/HorizontalDefault/index';
import HorizontalDark from '../Topbar/HorizontalDark/index';
import InsideHeader from '../Topbar/InsideHeader/index';
import AboveHeader from '../Topbar/AboveHeader/index';
import BelowHeader from '../Topbar/BelowHeader/index';
import Topbar from '../Topbar/index';
import { FOOTER_TEXT } from '../../util/config';
import App from '../../routes/index';
import {
	NAV_STYLE_ABOVE_HEADER,
	NAV_STYLE_BELOW_HEADER,
	NAV_STYLE_DARK_HORIZONTAL,
	NAV_STYLE_DEFAULT_HORIZONTAL,
	NAV_STYLE_DRAWER,
	NAV_STYLE_FIXED,
	NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
	NAV_STYLE_MINI_SIDEBAR,
	NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
	NAV_STYLE_NO_HEADER_MINI_SIDEBAR
} from '../../constants/ThemeSetting';
import NoHeaderNotification from '../Topbar/NoHeaderNotification/index';
import { useRouteMatch } from 'react-router-dom';
import { updateWindowWidth } from '../../appRedux/actions';
import AppSidebar from './AppSidebar';

const { Content, Footer } = Layout;

// Function to get the container class based on navStyle
const getContainerClass = (navStyle) => {
	switch (navStyle) {
		case NAV_STYLE_DARK_HORIZONTAL:
			return 'gx-container-wrap';
		case NAV_STYLE_DEFAULT_HORIZONTAL:
			return 'gx-container-wrap';
		case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
			return 'gx-container-wrap';
		case NAV_STYLE_BELOW_HEADER:
			return 'gx-container-wrap';
		case NAV_STYLE_ABOVE_HEADER:
			return 'gx-container-wrap';
		default:
			return '';
	}
};

// Function to get the navigation styles based on navStyle
const getNavStyles = (navStyle) => {
	switch (navStyle) {
		case NAV_STYLE_DEFAULT_HORIZONTAL:
			return <HorizontalDefault />;
		case NAV_STYLE_DARK_HORIZONTAL:
			return <HorizontalDark />;
		case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
			return <InsideHeader />;
		case NAV_STYLE_ABOVE_HEADER:
			return <AboveHeader />;
		case NAV_STYLE_BELOW_HEADER:
			return <BelowHeader />;
		case NAV_STYLE_FIXED:
			return <Topbar />;
		case NAV_STYLE_DRAWER:
			return <Topbar />;
		case NAV_STYLE_MINI_SIDEBAR:
			return <Topbar />;
		case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
			return <NoHeaderNotification />;
		case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
			return <NoHeaderNotification />;
		default:
			return null;
	}
};

const MainApp = () => {
	const { navStyle } = useSelector(({ settings }) => settings);
	const match = useRouteMatch();
	const dispatch = useDispatch();

	// Listen to window resize events and update window width in redux store
	useEffect(() => {
		window.addEventListener('resize', () => {
			dispatch(updateWindowWidth(window.innerWidth));
		});
	}, [dispatch]);

	return (
		<Layout className="gx-app-layout">
			{/* Sidebar */}
			<AppSidebar navStyle={navStyle} />

			{/* Main Layout */}
			<Layout>
				{/* Render the appropriate topbar based on navStyle */}
				{getNavStyles(navStyle)}

				{/* Content */}
				<Content className={`gx-layout-content ${getContainerClass(navStyle)} `}>
					{/* Render the main app based on the matched route */}
					<App match={match} />

					{/* Footer */}
					<Footer>
						<div className="gx-layout-footer-content">{FOOTER_TEXT}</div>
					</Footer>
				</Content>
			</Layout>
		</Layout>
	);
};

export default MainApp;
