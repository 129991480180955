import React, { useState } from 'react';
import { Button, Modal, Form, Input, message } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';

import { useAuth } from '../authentication';
import AppNotificationContainer from '../components/AppNotificationContainer';

import { CLOUDINARY_URL } from '../util/config';

const SignIn = () => {
	const {
		isLoading,
		error,
		userLogin,
		successMessage,
		authUser,
		resetPassword,
		setSuccessMessage,
		setError,
		forgotPassword
	} = useAuth(); //getting authentication props from useAuth()
	const [messageApi, contextHolder] = message.useMessage(); //This method is particularly useful when you want to display notification message.

	const location = useLocation();
	const query = new URLSearchParams(location?.state?.from?.search); //stores the url params coming from reset password link which contains reset token inside it
	const resetToken = query.get('resetToken');
	const [resetTokenState, setResetTokenState] = useState(resetToken);
	const resetEmail = query.get('email');

	const history = useHistory();
	const onFinishFailed = (errorInfo) => {};
	const [isModalOpen, setIsModalOpen] = useState(false);
	const onFinish = (values) => {
		userLogin(values);
		if (authUser) {
			history.push('/dashboard');
		}
	};
	const handleOk = () => {
		setIsModalOpen(false);
	};
	const forgotSubmit = (values) => {
		forgotPassword(values, handleOk());
	};
	const handleOkForReset = () => {
		setResetTokenState('');
	};
	const resetSubmit = (values) => {
		const data = {
			email: resetEmail,
			password: values.password,
			resetToken: resetToken
		};
		resetPassword(data, messageApi, handleOkForReset);
	};
	const showModal = () => {
		setIsModalOpen(true);
		setSuccessMessage('');
		setError('');
	};

	const handleCancel = () => {
		setIsModalOpen(false);
		setResetTokenState('');
	};

	return (
		<>
			{contextHolder}
			{/* to get the notification */}

			{/*login content start*/}
			<div className="gx-app-login-wrap">
				<div className="gx-app-logo">
					<img alt="logo" src={`${CLOUDINARY_URL}/logo.png`} />
				</div>
				<br />
				<div className="gx-app-login-container">
					<div className="gx-app-login-main-content">
						<div className="gx-app-logo-content">
							<div className="gx-app-logo-content-bg"></div>
							<div className="gx-app-logo-wid">
								<h1>
									<IntlMessages id="app.userAuth.signIn" />
								</h1>
								<p>
									<IntlMessages id="app.userAuth.bySigning" />
								</p>
							</div>
						</div>
						<div className="gx-app-login-content">
							<Form
								initialValues={{ remember: true }}
								name="basic"
								onFinish={onFinish}
								onFinishFailed={onFinishFailed}
								className="gx-signin-form gx-form-row0"
							>
								<Form.Item
									rules={[
										{
											required: true,
											message: 'The input is not valid E-mail!'
										}
									]}
									name="email"
								>
									<Input placeholder="Email" />
								</Form.Item>
								<Form.Item
									rules={[
										{ required: true, message: 'Please input your Password!' }
									]}
									name="password"
								>
									<Input type="password" placeholder="Password" />
								</Form.Item>
								<Form.Item>
									<Button
										type="link"
										className="gx-p-0 gx-m-0"
										onClick={showModal}
									>
										Forgot password
									</Button>

									<Button type="link" className="gx-p-0 gx-m-0">
										<a
											href="https://alpha-b2b.coreyo.com/"
											target="_blank"
											rel="noreferrer"
											className="gx-p-0 gx-m-0"
										>
											ℹ️ Try upcoming Business Portal V2 (alpha stage)
										</a>
									</Button>
								</Form.Item>

								<Form.Item>
									<Button type="primary" className="gx-mb-0" htmlType="submit">
										<IntlMessages id="app.userAuth.signIn" />
									</Button>
									<span>
										<IntlMessages id="app.userAuth.or" />
									</span>{' '}
									<Link to="/signup">
										<IntlMessages id="app.userAuth.signUp" />
									</Link>
								</Form.Item>
							</Form>
						</div>
						<AppNotificationContainer
							loading={isLoading}
							error={error}
							message={successMessage}
						/>
					</div>
				</div>
				<Modal
					// title="Forgot Password"
					open={isModalOpen}
					onOk={handleOk}
					onCancel={handleCancel}
					okText="Submit"
					style={{ top: 100 }}
					className="address__modal"
				>
					<h2 className="text-center">Forgot Password</h2>
					<p className="gx-text-grey text-center">
						We'll send you a link to reset your password
					</p>
					<Form
						initialValues={{ remember: true }}
						name="basic"
						onFinish={forgotSubmit}
						onFinishFailed={onFinishFailed}
						className="gx-signin-form"
					>
						<Form.Item
							rules={[{ required: true, message: 'The input is not valid E-mail!' }]}
							name="email"
							label="Email Address"
						>
							<Input placeholder="Email" />
						</Form.Item>
						<Button
							type="default gx-bg-yellow"
							className="gx-mb-0 flex gx-m-auto"
							htmlType="submit"
						>
							Submit
						</Button>
					</Form>
				</Modal>
				<Modal
					// title="Forgot Password"
					open={resetTokenState}
					onOk={handleOkForReset}
					onCancel={handleCancel}
					okText="Submit"
					style={{ top: 100 }}
					className="address__modal"
				>
					<h2 className="text-center">Update Your Password</h2>
					<p className="gx-text-grey text-center">
						<b>{resetEmail}</b>
					</p>
					<Form
						initialValues={{ remember: true }}
						name="basic"
						onFinish={resetSubmit}
						onFinishFailed={onFinishFailed}
						className="gx-signin-form"
					>
						<Form.Item
							rules={[{ required: true, message: 'The input is not valid E-mail!' }]}
							name="password"
							label="Password"
						>
							<Input placeholder="Password" />
						</Form.Item>
						<Button
							type="default gx-bg-yellow"
							className="gx-mb-0 flex gx-m-auto"
							htmlType="submit"
						>
							Submit
						</Button>
					</Form>
				</Modal>
			</div>
		</>
	);
};

export default SignIn;
